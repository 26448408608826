import React, { useState, useEffect } from "react";
import { Button,  Box, Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { cargarNFC, sendGetPassengerFiltered, sendGetQR } from "../../core/passenger/asyncPassenger";
import DownloadQr from "../../components/cardQr/indexOBERÁ";
import DownloadNFC from "../../components/cardNFC/NFC-Oberá";
import DownloadNFC70 from "../../components/cardNFC/NFC70-Oberá";
import DownloadNFCPos from "../../components/cardNFC/NFC-Posadas";
import DownloadQrPS from "../../components/cardQr/indexPOSADAS"
import DonwloadPSADULTOS from "../../components/cardQr/ADULTOSPOSADAS"
import DonwloadPSDISCAPACITADOS from "../../components/cardQr/DISCAPACITADOSPS"
import BEEG from "../../components/cardQr/BEGPS"
import DonwloadQrMayores from "../../components/cardQr/QrMayoresObera"
import DonwloadQrMenores from "../../components/cardQr/QrMenores"
import { readfiles } from "../../components/cardQr/QrMayoresObera";
import { useLocation } from "react-router-dom";
import TabPanel from "../../components/TabPanel/index";

export default function CardPassenger({ qr, id , desde, hasta, nfc}) {
  const [download, setDownload] = useState(false);
  const [downloadPS, setDownloadPS] = useState(false);
  const [downloadMayores, setDownloadMayores] = useState(false);
  const [downloadMenores, setDownloadMenores] = useState(false);
  const [DonwloadPSADULTO, setDownloadPSADULTOS] = useState(false);
  const [DonwloadPSDISCAPACITAD, setDONWLOADPSDISCAPACITADOS] = useState(false);
  const [DonwloadPSBEEG, setDonwloadPSBEEG] = useState(false)
  const [downloadNFC, setDownloadNFC] = useState(false); 
  const [downloadNFCPos, setDownloadNFCPos] = useState(false); 
  const [downloadNFC70, setDownloadNFC70] = useState(false); 
  const dispatch = useDispatch();
  const { updatePassengerSuccess, updatePassengerFailed } = useSelector((state) => state.passenger);
  const [previousImage, setPreviousImage] = useState([]);

  useEffect(() => {
    if (updatePassengerSuccess) {
      sweetAlert("Exito", "Se actualizo el pasajero", "success");
    }
    if (updatePassengerFailed) {
      sweetAlert("Error", "No se pudo actualizar el pasajero", "error");
    }
  }, [updatePassengerSuccess, updatePassengerFailed]);

  function handleDownloadQR() {
    setDownload(true);
    dispatch(sendGetQR(id));
    dispatch(sendGetPassengerFiltered(dataRow?.dni));
  }
  function handleDownloadQRMENORES() {
    setDownloadMenores(true);
    dispatch(sendGetQR(id));
    dispatch(sendGetPassengerFiltered(dataRow?.dni));
  }
  function handleDownloadQRMayores() {
    setDownloadMayores(true);
    dispatch(sendGetQR(id));
    dispatch(sendGetPassengerFiltered(dataRow?.dni));
  }
  function handleDownloadQRPS() {
    setDownloadPS(true);
    dispatch(sendGetQR(id));
    dispatch(sendGetPassengerFiltered(dataRow?.dni));
  }
  function handleDownloadQRPSADULTOS() {
    setDownloadPSADULTOS(true);
    dispatch(sendGetQR(id));
    dispatch(sendGetPassengerFiltered(dataRow?.dni));
  }
  function handleDownloadQRPSDISCAPACITADOS() {
    setDONWLOADPSDISCAPACITADOS(true);
    dispatch(sendGetQR(id));
    dispatch(sendGetPassengerFiltered(dataRow?.dni));
  }
  function handleDownloadQRPSBEEG() {
    setDonwloadPSBEEG(true);
    dispatch(sendGetQR(id));
    dispatch(sendGetPassengerFiltered(dataRow?.dni));
  }

  function changeInput(e) {
    setPreviousImage(readfiles(e))
  };
  const location = useLocation();
  const { dataRow } = location.state;
  function getTabProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
      sx: { textTransform: "none", color: "#ff7f2a" },
    };
  }
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (_event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <>
      {downloadNFC70 && (
    <DownloadNFC70
   lastName={dataRow?.lastName}
    name={dataRow?.name}
      setDownload={() => setDownloadNFC70(false)}
       nfc
        />
        )}
          {downloadNFCPos && (
    <DownloadNFCPos
   lastName={dataRow?.lastName}
    name={dataRow?.name}
      setDownload={() => setDownloadNFCPos(false)}
       nfc
        />
        )}
      {download && qr && (<DownloadQr
        {...{
          desde : desde,
          hasta : hasta,
          dni: dataRow?.dni,
          name: dataRow?.name,
          lastName: dataRow?.lastName,
          setDownload: () => setDownload(false), qr
        }} />)}
     {downloadNFC && (
    <DownloadNFC
   lastName={dataRow?.lastName}
    name={dataRow?.name}
      setDownload={() => setDownloadNFC(false)}
       nfc
        />
        )}
      {download && qr && (<DownloadQr
        {...{
          desde : desde,
          hasta : hasta,
          dni: dataRow?.dni,
          name: dataRow?.name,
          lastName: dataRow?.lastName,
          setDownload: () => setDownload(false), qr
        }} />)}
      {downloadPS && qr && (<DownloadQrPS
        {...{
          desde : desde,
          hasta : hasta,
          dni: dataRow?.dni,
          name: dataRow?.name,
          lastName: dataRow?.lastName,
          setDownloadPS: () => setDownloadPS(false), qr
        }} />)}
      {DonwloadPSADULTO && qr && (<DonwloadPSADULTOS
        {...{
          desde : desde,
          hasta : hasta,
          dni: dataRow?.dni,
          name: dataRow?.name,
          lastName: dataRow?.lastName,
          setDownloadPSADULTOS: () => setDownloadPSADULTOS(false), qr
        }} />)}
      {downloadMenores && qr && (<DonwloadQrMenores
        {...{
          desde : desde,
          hasta : hasta,
          dni: dataRow?.dni,
          name: dataRow?.name,
          lastName: dataRow?.lastName,
          setDownloadMenores: () => setDownloadMenores(false), qr,
        }} />)}
      {downloadMayores && qr && (
        <DonwloadQrMayores
          {...{
            desde : desde,
            hasta : hasta,
            dni: dataRow?.dni,
            name: dataRow?.name,
            lastName: dataRow?.lastName,
            setDownloadMayores: () => setDownloadMayores(false), qr,
            previousImage
          }} />)}
      {DonwloadPSDISCAPACITAD && qr && (<DonwloadPSDISCAPACITADOS
        {...{
          desde : desde,
          hasta : hasta,
          dni: dataRow?.dni,
          name: dataRow?.name,
          lastName: dataRow?.lastName,
          setDONWLOADPSDISCAPACITADOS: () => setDONWLOADPSDISCAPACITADOS(false), qr
        }} />)}
      {DonwloadPSBEEG && qr && (<BEEG
        {...{
          desde : desde,
          hasta : hasta,
          dni: dataRow?.dni,
          name: dataRow?.name,
          lastName: dataRow?.lastName,
          setDonwloadPSBEEG: () => setDonwloadPSBEEG(false), qr
        }} />)}

  
      <div className="botonBtn">
        <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">
          Generar QR
        </button>
        <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".bd-nfc-modal-lg">
          Generar NFC
        </button>
      </div>


      <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Generar QR</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  aria-label="QR">
                  <Tab label="OBERÁ" {...getTabProps(0)} />
                  <Tab label="POSADAS" {...getTabProps(1)} />
                  <Tab label="METROPOLITANO" {...getTabProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={tabIndex} index={0}>
                <div className="parte-obera">
                  <div>
                    <Button onClick={(e) => { e.preventDefault(); handleDownloadQR(); }}> QR Estatico</Button>
                    <Button onClick={(e) => { e.preventDefault(); handleDownloadQRMENORES(); }}> Tarjeta Menores</Button>
                  </div>
                  <div className="ApartadoFoto">
                    <h6 className="typeKeyDescription">Agregar una foto de perfil : </h6>
                    <div className="apartado1">
                    <input type="file" name="Foto del Usuario" onChange={changeInput} />
                    <Button onClick={handleDownloadQRMayores}> QR Jubilados</Button>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <div className="parte-posadas">
                  <Button onClick={(e) => { e.preventDefault(); handleDownloadQRPSADULTOS(); }}> Adultos Mayores</Button>
                  <Button onClick={(e) => { e.preventDefault(); handleDownloadQRPSDISCAPACITADOS(); }}> QR Discapacitados</Button>
                  <Button onClick={(e) => { e.preventDefault(); handleDownloadQRPSBEEG(); }}> QR BEEG</Button>
                </div>
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <div className="parte-metropolitana">
                  <Button onClick={(e) => { e.preventDefault(); handleDownloadQRPS(); }}> QR Estatico (SUSA)</Button>
                </div>
              </TabPanel>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

    {/* modal para generacion de NFC */}
      <div className="modal fade bd-nfc-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Generar NFC</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            
          <div className="modal-body">
  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="NFC">
      <Tab label="POSADAS" {...getTabProps(0)} />
      <Tab label="OBERÁ" {...getTabProps(1)} />
    </Tabs>
  </Box>

  <TabPanel value={tabIndex} index={0}>
    <div className="posadas">
      <Button onClick={() => setDownloadNFCPos(true)}>Generar NFC</Button>
    </div>
  </TabPanel>

  <TabPanel value={tabIndex} index={1}>
    <div className="obera">
      <Button onClick={() => setDownloadNFC(true)}>Generar NFC</Button>
      <Button onClick={() => setDownloadNFC70(true)}>Generar NFC +70</Button>
    </div>
  </TabPanel>
</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <table className="table">
        <thead key="thead">
          <tr>
            <th scope="col">Nombre y Apellido</th>
            <th scope="col">Dni</th>
            <th scope="col">Cuit</th>
            <th scope="col">Ciudad</th>
            <th scope="col">Nacido en</th>
            <th scope="col">Email</th>
            <th scope="col">Telefono</th>
            <th scope="col">Direccion</th>
          </tr>
        </thead>
        <tbody key="tbodyz">
          <tr>
            <th scope="row">{dataRow && dataRow?.name ? dataRow?.name : 'Cargando...' + " " + dataRow && dataRow?.lastName ? dataRow?.lastName : 'Cargando...'}</th>
            <td >{dataRow?.dni}</td>
            <td>{dataRow?.cuit}</td>
            <td>{dataRow?.city}</td>
            <th scope="row">{dataRow?.birthDate}</th>
            <td>{dataRow?.email}</td>
            <td >{dataRow?.phoneNumber}</td>
            <td>{dataRow?.address}</td>
          </tr>
        </tbody>
      </table>


    </>
  );
}
